










declare var Morris: any;

import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import moment from 'moment';
import { IDMConsumptionPrepopulatedDataResponse } from "@/interfaces/dmConsumptionPrepopulatedDataResponse";
import { IDMDownloadConsumptionGraphRequestParams } from "@/interfaces/dmDownloadConsumptionGraphRequestParams";
import { IDMConsumptionGraphDataResponse } from "@/interfaces/dmConsumptionGraphDataResponse";

import { FileProcess } from "@/utilities/FileProcess";

import * as jQuery from 'jquery';

import { IDMHistoricConsumption } from "@/interfaces/dmHistoricConsumption";

const GasAccounts = namespace("GasAccounts");
const DMConsumptionReports = namespace("DMConsumptionReports");
const Auth = namespace("Auth");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepDMConsumptionReports extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private EndDate = '';
    private MeterPointReferenceNumber = '';
    private MeterSerialNumber = '';

    private ShowGraph = false;
    private processing = false;
    private ShowClientSideValidationMessage = false;

    private GraphData = [];

    private FullDMConsumptionDataTooltipTitle = '<p class="text-left">Download full consumption data for date range selected.</br></br>Please note: </br> "-1" = (a) Non Consuming Period (b) Data not yet provided by the industry</p>';

    private FileProcess = new FileProcess();

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @DMConsumptionReports.Getter
    private getPrepopulatedData!: IDMConsumptionPrepopulatedDataResponse;

    @DMConsumptionReports.Getter
    private getDMConsumptionGraphData!: IDMConsumptionGraphDataResponse;

    @DMConsumptionReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @DMConsumptionReports.Action
    private submitGetPrepopulatedValues!: (params: { userId: string, accountNum: string, siteRef: string }) => Promise<void>;

    @DMConsumptionReports.Action
    private submitDMDownloadConsumptionGraph!: (params: IDMDownloadConsumptionGraphRequestParams) => Promise<void>;

    @DMConsumptionReports.Action
    private submitGetDMConsumptionGraph!: (params: IDMDownloadConsumptionGraphRequestParams) => Promise<void>;

    createDMConumptionGraph(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                return;
            }

            var days = moment(this.EndDate).diff(moment(this.StartDate), 'days');
            console.log(days);
            if (days >= 730) {
                this.ShowClientSideValidationMessage = true;
                this.ShowGraph = false;
                return;
            } else {

                //jQuery errors are suppressed to get graph functional
                //Code will need refactoring to move code to backend

                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                jQuery("#area-example").empty();
                this.processing = true;
                this.ShowGraph = false;
                this.ShowClientSideValidationMessage = false;

                try {
                    this.DMDownloadConsumptionGraphParams.userId = this.getUserId;
                    this.DMDownloadConsumptionGraphParams.accountNumber = this.getSelectedAccount;
                    this.DMDownloadConsumptionGraphParams.siteRef = this.getSelectedSiteRefNumber;                        
                    this.DMDownloadConsumptionGraphParams.startDate = this.StartDate;
                    this.DMDownloadConsumptionGraphParams.endDate = this.EndDate;
                    this.DMDownloadConsumptionGraphParams.meterPointReferenceNumber = this.getPrepopulatedData.SelectedMeterPointReference;

                    this.submitGetDMConsumptionGraph(this.DMDownloadConsumptionGraphParams).then(() => {

                        var historicConsumption = this.getDMConsumptionGraphData.HistoricConsumption;
                        var yObj = [];

                        jQuery.each(historicConsumption,
                            function (i, item: IDMHistoricConsumption) {
                                var obj = {
                                    date: item.GasDay,
                                    totalKwh: item.TotalkWh,
                                    soq: item.Soq,
                                    label: moment(item.GasDay).format('DD-MM-YYYY')
                                };

                                // Push an element on the day of the SOQ change for display purposes
                                // @ts-expect-error properties are not accessible on undefined object error expected
                                if (yObj != null && yObj[yObj.length - 1] !== undefined && yObj[yObj.length - 1].soq !== obj.soq) {
                                    var itm = Object.assign({}, obj);
                                    // @ts-expect-error cannot access property from undefined object error expected
                                    itm.soq = yObj[yObj.length - 1].soq;
                                    // @ts-expect-error cannot access property from undefined object error expected
                                    yObj.push(itm);
                                }

                                // @ts-expect-error cannot add object to undefined object
                                yObj.push(obj);
                            });

                        jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/raphael/2.1.0/raphael-min.js',
                            function () {
                                jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/morris.js/0.5.0/morris.min.js',
                                    function () {
                                        Morris.Line({
                                            element: 'area-example',
                                            data: yObj,
                                            xkey: 'date',
                                            ykeys: ['totalKwh', 'soq'],
                                            labels: ['Total kWh', 'SOQ'],
                                            lineColors: ['#0b62a4', '#cc0000'],
                                            gridTextColor: '#000000',
                                            pointSize: [4, 0],
                                            smooth: false,
                                            xLabelFormat: function (d) {
                                                return d.getDate() +
                                                    "/" +
                                                    (d.getMonth() + 1) +
                                                    "/" +
                                                    d.getFullYear();
                                            },
                                            resize: 'true',
                                            hoverCallback: function (index, options, content) {
                                                var data = options.data[index];
                                                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                //@ts-ignore
                                                jQuery(".morris-hover")
                                                    .html('<b>SOQ: </b>' +
                                                        data.soq +
                                                        '<br/><b>Date: </b>' +
                                                        data.label +
                                                        '<br/><b>Total kWh: </b>' +
                                                        data.totalKwh);

                                            }
                                        });
                                    });
                            });

                        this.ShowGraph = true;
                        this.processing = false;
                    }, (error) => {
                        this.ShowGraph = false;
                        this.processing = false;
                        console.log(error.response.data.message);
                    });
                } catch (error) {
                    this.ShowGraph = false;
                    this.processing = false;
                }
            }

        });

        
    }

    private DMDownloadConsumptionGraphParams: IDMDownloadConsumptionGraphRequestParams =
    {
        userId: null,
        accountNumber: null,
        siteRef: null,
        startDate: null,
        endDate: null,
        meterPointReferenceNumber: null
    }

    DownloadDMConsumptionGraph(): void {
        this.DMDownloadConsumptionGraphParams.userId = this.getUserId;
        this.DMDownloadConsumptionGraphParams.accountNumber = this.getSelectedAccount;
        this.DMDownloadConsumptionGraphParams.siteRef = this.getSelectedSiteRefNumber;
        this.DMDownloadConsumptionGraphParams.startDate = this.StartDate;
        this.DMDownloadConsumptionGraphParams.endDate = this.EndDate;
        this.DMDownloadConsumptionGraphParams.meterPointReferenceNumber = this.getPrepopulatedData.SelectedMeterPointReference;

        this.submitDMDownloadConsumptionGraph(this.DMDownloadConsumptionGraphParams).then(() => {
            this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
        }, (error) => {
            console.error(error.response.data);
        });
    }

    mounted(): void {

        try {
            this.submitGetPrepopulatedValues({ userId : this.getUserId, accountNum: this.getSelectedAccount,  siteRef: this.getSelectedSiteRefNumber }).then(() => {
                this.MeterSerialNumber = this.getPrepopulatedData.SelectedMeterSerial;
                this.MeterPointReferenceNumber = this.getPrepopulatedData.SelectedMeterPointReference;
            });
        } catch (error) {
            console.log(error);
        }
    }

}

