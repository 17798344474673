






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"    
import { ICogBasket } from "@/interfaces/cogBasket";
import { ICogReport } from "@/interfaces/cogReport";
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../../../utilities/GtagHelper';

import moment from 'moment';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const CostOfGasReports = namespace("CostOfGasReports");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepCostOfGasReports extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private processing = false;
    private loading = false;

    private GtagHelper = new GtagHelper();

    public cogReportsTableColumns = [
        {
            key: 'ReportDate',
            label: 'Date issued',
            formatter: this.formatDate
        },
        {
            key: 'FileName',
            label: 'File name'
        },
        {
            key: 'FilePath',
            label: 'Download'
        }];

    private selectedBasketId: (number | null) = null;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @CostOfGasReports.Getter
    private getBaskets!: ICogBasket[];

    @CostOfGasReports.Getter
    private getCogReports!: ICogReport[];

    @CostOfGasReports.Mutation
    private clearState!: () => void;

    @CostOfGasReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };


    @CostOfGasReports.Action
    private fetchBaskets!: (params: { userId: string }) => Promise<void>;

    @CostOfGasReports.Action
    private fetchCogReports!: (params: { userId: string, basketId: number }) => Promise<void>;

    @CostOfGasReports.Action
    private downloadCogReport!: (params: { userId: string, filePath: string }) => Promise<void>;

    @CostOfGasReports.Action
    private downloadCogReports!: (params: { userId: string, basketId: number | null }) => Promise<void>;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;
                        
    onBulkDownload(requestAll: boolean): void {

        try {

            var params = {
                userId: this.getUserId,
                basketId: requestAll ? null : this.selectedBasketId
            }

            this.downloadCogReports(params).then(() => {

                var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                if (success) {
                    this.$gtag.event('LumiDownloadCogReport', { userId: this.getUserId, value: 'Bulk' });
                }
            }, (error) => {
                console.error(error.response.data);
            })

        } catch (error) {
            console.log(error.response.data);
        }
    }
    
    private FileProcess = new FileProcess();

    private async onDownloadCogReport(filePath : string): Promise<void> {            

        try {

            const params = {
                userId: this.getUserId,
                filePath: filePath
            }

            await this.downloadCogReport(params);
            
            var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
            if (success) {
                this.GtagHelper.Event("LumiDownloadCogReport", this.getUserId);
            }
           
        } catch (e) {
            console.error(e);                
        }
    }
   
    public async loadBaskets() {

        this.loading = true;

        await this.fetchBaskets({ userId: this.getUserId }).then(() => {

            this.loading = false;

        }).catch(reason => {
            this.loading = false;
            console.error(reason);
        });
    }       

    public async loadAvailableCogReports() {

        this.loading = true;

        await this.fetchCogReports({ userId: this.getUserId,basketId:this.selectedBasketId ?? -1 }).then(() => {

            this.loading = false;

        }).catch(reason => {
            this.loading = false;
            console.error(reason);
        });
    }

    private formatDate(value: Date): string {

        return moment(value).format('MMM-YY');
    }

    mounted() {
        this.clearState();

        this.setAccountSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
        this.setMprnSelectionDisabled(true);

        this.loadBaskets();
    }

    beforeDestroy(): void {
        this.setAccountSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
        this.setMprnSelectionDisabled(false);
    }
}
