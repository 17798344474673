

































































































declare var Morris: any;

import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import moment from 'moment';

import { IPowerConsumptionReportsRequestParams } from '@/interfaces/powerConsumptionReportsRequestParams';
import { IPowerConsumptionReportsRequestMultiDateParams } from '@/interfaces/powerConsumptionReportsRequestMultiDateParams';


import * as jQuery from 'jquery';

const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const PowerConsumptionReports = namespace("ConsumptionReportsPower");
const Auth = namespace("Auth");

import { FileProcess } from "@/utilities/FileProcess";

import { GtagHelper } from '../../../../utilities/GtagHelper';

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepConsumptionReportsForHalfHourlyConsumption extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private FirstGraphLineDate = '';
    private AdditionalDate = '';
    private ShowAdditionalDateField = true;

    private processing = false;

    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;
    private graphProcessing = false;
    private ShowGraph = false;
    private AdditionalDates: string[] = [];

    private lineColors = ['#009FDA', '#DD7A00', '#B32B31', '#83B81A', '#FFC608'];

    private GtagHelper = new GtagHelper();

    @PowerAccounts.Getter
    private getSelectedAccount!: string;

    @PowerConsumptionReports.Action
    private submitGetGraphData!: (params: IPowerConsumptionReportsRequestParams) => Promise<void>;

    @PowerConsumptionReports.Action
    private submitGetAdditionalGetGraphData!: (params: IPowerConsumptionReportsRequestParams) => Promise<void>;

    @PowerConsumptionReports.Action
    private submitDownlaodDailyConsumptionReport!: (params: IPowerConsumptionReportsRequestParams) => Promise<void>;

    @PowerConsumptionReports.Action
    private submitDownlaodHalfHourlyMultiDate!: (params: IPowerConsumptionReportsRequestMultiDateParams) => Promise<void>;

    @PowerConsumptionReports.Getter
    private getGraphData!: string;

    @PowerConsumptionReports.Getter
    private getAdditionalGraphData!: string;

    @Auth.Getter
    private getUserId!: string;

    @PowerAccounts.Getter
    private getSelectedMpan!: string;

    @PowerConsumptionReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    private FileProcess = new FileProcess();

    private powerConsumptionReportsRequestParams: IPowerConsumptionReportsRequestParams =
    {
        userId: null,
        accountNumber: null,
        mpan: null,
        fromDate: null,
        toDate: null,
    }

    private powerConsumptionReportsRequestMultiDateParams: IPowerConsumptionReportsRequestMultiDateParams =
    {
        userId: null,
        accountNumber: null,
        mpan: null,
        dates: null
    }

    onDownloadReport(): void {
        try {
            if (this.AdditionalDates.length === 0) {
                this.powerConsumptionReportsRequestParams.userId = this.getUserId;
                this.powerConsumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
                this.powerConsumptionReportsRequestParams.mpan = this.getSelectedMpan;
                this.powerConsumptionReportsRequestParams.fromDate = this.StartDate;
                this.powerConsumptionReportsRequestParams.toDate = this.StartDate;

                this.submitDownlaodDailyConsumptionReport(this.powerConsumptionReportsRequestParams).then(() => {
                    this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                }, (error) => {
                    this.graphProcessing = false;
                })
            } else {
                var Dates = this.AdditionalDates.slice();
                Dates.unshift(this.FirstGraphLineDate);
                var CommaSeperatedDates = Dates.join();
                this.powerConsumptionReportsRequestMultiDateParams.userId = this.getUserId;
                this.powerConsumptionReportsRequestMultiDateParams.accountNumber = this.getSelectedAccount;
                this.powerConsumptionReportsRequestMultiDateParams.mpan = this.getSelectedMpan;
                this.powerConsumptionReportsRequestMultiDateParams.dates = CommaSeperatedDates;
                this.submitDownlaodHalfHourlyMultiDate(this.powerConsumptionReportsRequestMultiDateParams).then(() => {
                    this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                }, (error) => {
                    this.graphProcessing = false;
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    onShow(): void {
        this.$validator.validate("halfHourlyConsumptionReportDateFrom").then((isValid) => {
            if (!isValid) {
                return;
            }

            this.graphProcessing = true;
            this.ShowGraph = false;
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery("#chart").empty();

            try {

                this.powerConsumptionReportsRequestParams.userId = this.getUserId;
                this.powerConsumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
                this.powerConsumptionReportsRequestParams.mpan = this.getSelectedMpan;
                this.powerConsumptionReportsRequestParams.fromDate = this.StartDate;
                this.powerConsumptionReportsRequestParams.toDate = this.StartDate;

                this.submitGetGraphData(this.powerConsumptionReportsRequestParams).then(() => {
                    this.GenerateGraph(JSON.parse(this.getGraphData));
                    this.graphProcessing = false;
                    this.ShowGraph = true;

                    this.GtagHelper.Event("LumiShowPowerHHConsumptionGraph", this.getUserId);
                }, (error) => {
                    this.graphProcessing = false;
                })

            } catch (error) {
                this.processing = false;
                this.downloadProgressCounter = 0;
                this.graphProcessing = false;
            }
        });
    }

    beforeMount() {
        const dict = {
            custom: {
                halfHourlyConsumptionReportDateFrom: {
                    required: 'Report date is required'
                },
                halfHourlyConsumptionReportAdditionalDate: {
                    required: 'Please enter a date'
                }

            }
        };

        this.$validator.localize('en', dict);


    }

    AddAdditionalDate(): void {
        this.$validator.validate("halfHourlyConsumptionReportAdditionalDate").then((isValid) => {
            if (!isValid) {
                return;
            }

            this.powerConsumptionReportsRequestParams.userId = this.getUserId;
            this.powerConsumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
            this.powerConsumptionReportsRequestParams.mpan = this.getSelectedMpan;
            this.powerConsumptionReportsRequestParams.fromDate = this.AdditionalDate;
            this.powerConsumptionReportsRequestParams.toDate = this.AdditionalDate;
            try {
                this.submitGetAdditionalGetGraphData(this.powerConsumptionReportsRequestParams).then(() => {
                    if (this.getAdditionalGraphData.length === 0) {
                        return;
                    }

                    var FormattedAdditionalDate = moment(this.AdditionalDate).format('DD/MM/YYYY');
                    if (this.AdditionalDates.includes(FormattedAdditionalDate) || this.FirstGraphLineDate === FormattedAdditionalDate) {

                        var interestedButton = jQuery("button[data-date='" + FormattedAdditionalDate + "']");

                        if (interestedButton.length) {
                            interestedButton.addClass('bounceIn');
                        }

                        setTimeout(function () { interestedButton.removeClass('bounceIn') }, 1000);

                        return;
                    }

                    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    window.lineColors = this.lineColors;
                    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    var max = this.CalcMaxSimple(window.GraphObj);
                    var StartDate = moment(this.StartDate).format('DD/MM/YYYY');

                    var freshObj = [];

                    function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

                    jQuery.each(JSON.parse(this.getAdditionalGraphData), function (d, value) {

                        var date = moment(value["SD"]);

                        var obj = {};

                        jQuery.each(value, function (i, item) {
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            if (i === "SD" || i === "MAX" || i === "TOT" || i === "24:00" || i === "24:30") {
                                return true;
                            }

                            obj = _defineProperty({
                                x: i
                            }, date.format("DD/MM/YYYY"), item);
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            freshObj.push(obj);
                        });

                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        var expected = window.GraphObj.map(function (a) {
                            return Object.assign(a, freshObj.find(function (b) {
                                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                return b.x === a.x;
                            }));
                        });

                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.options.lineColors = window.lineColors;
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.options.ykeys.push(moment(date).format("DD/MM/YYYY"));
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.options.labels[0] = StartDate;
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.options.labels.push(moment(date).format("DD/MM/YYYY"));
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.options.hoverCallback = function (index, options, content) {
                            var data = options.data[index];
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            jQuery(".morris-hover").html('<div><b>' + data.x + '</b>' + content + '</div>');
                        };

                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        if (window.a.options.labels.length > 4) {
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            jQuery("#addDateContainer").hide();
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            jQuery("#addDateButtonContainer").hide();
                        }

                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.options.ymax = max;
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        window.a.setData(expected);
                    });

                    if (this.AdditionalDate != '') {
                        this.AdditionalDates.push(moment(this.AdditionalDate).format('DD/MM/YYYY'));
                    }

                    this.AdditionalDate = '';

                    this.$validator.reset();


                }, (error) => {
                    return;
                })
            } catch (error) {
                return;
            }
        });
    }

    RemoveAdditionalDate(date) {

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.GraphObj.forEach(function (v) {
            delete v[moment(date).format('DD/MM/YYYY')];
        });

        var IndexOfAdditionalDateRemoved = this.AdditionalDates.indexOf(date);
        this.AdditionalDates.splice(IndexOfAdditionalDateRemoved,1);

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.a.options.ykeys = window.a.options.ykeys.filter(function (a) {
            return a !== date;
        });
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.a.options.labels = window.a.options.labels.filter(function (a) {
            return a !== date;
        });

        if (this.AdditionalDates.length === 0) {
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.a.options.lineColors = window.lineColors; //reset the colors
        } else {
            var colorToShift = this.lineColors[IndexOfAdditionalDateRemoved + 1];

            this.lineColors = this.lineColors.filter(function (a) {
                return a !== colorToShift;
            });
            this.lineColors.push(colorToShift);
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.lineColors = this.lineColors;
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.a.options.lineColors = this.lineColors; //reset the colors
        }

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.a.options.hoverCallback = function (index, options, content) {
            var data = options.data[index];
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery(".morris-hover").html('<div><b>' + data.x + '</b>' + content + '</div>');
        };

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (this.AdditionalDates.length < 5) {
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery("#addDateContainer").show();
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery("#addDateButtonContainer").show();
        }

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.a.options.ymax = this.CalcMaxSimple(window.GraphObj);

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.a.setData(window.GraphObj);
    }

    GenerateGraph(data: any) {

        var hideAxis = data.length > 31; //restrict axis to 31 days for performance
        var graphObj = [];

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        jQuery.each(data, function (d, value) {

            var date = new Date(value["SD"]);

            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery.each(value, function (i, item) {

                var obj = {};

                if (i === "SD" || i === "MAX" || i === "TOT" || i === "24:00" || i === "24:30") {
                        return true;
                }

                obj = {
                    x: i,
                    y: item
                };

                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                graphObj.push(obj);
            });
        });

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.GraphObj = graphObj;
        this.LoadGraph(graphObj, hideAxis);

        this.FirstGraphLineDate = moment(this.StartDate).format('DD/MM/YYYY');
        this.AdditionalDates = [];
    }

    LoadGraph(data, hideAxis) {

        var currentDates = [];
        var max = this.CalcMaxSimple(data);
        var axes = '';
        var graphLabelUnit = "Time";

            var hoverCallback = function (index, options, content) {
                var d = options.data[index];
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                jQuery(".morris-hover").html('<div><b> ' + graphLabelUnit + ':</b> ' + d.x + '<br/><b> Total kWh:</b> ' + d.y + '</div>');
            };

            if (hideAxis) {
                axes = 'y';
            }

            var xLabelFormat = function (x) {
                var date = moment(x.label, "DD/MM/YYYY HH:mm");

                var stringDate = date.format("DD/MM/YYYY");

                if (stringDate === "Invalid date") {
                    return '\xa0';
                }

                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                if (jQuery.inArray(stringDate, currentDates) === -1) {
                    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    currentDates.push(stringDate);
                    return stringDate;
                }

                return '\xa0';
            };
            
            jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/raphael/2.1.0/raphael-min.js',
                function () {
                    jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/morris.js/0.5.0/morris.min.js',
                        function () {
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            window.a = Morris.Line({
                                element: 'chart',
                                data: data,
                                axes: undefined,
                                xkey: ['x'],
                                ykeys: ['y'],
                                ymax: max,
                                ymin: 0,
                                labels: ['Kwh'],
                                lineColors: ['#0b62a4'],
                                gridTextColor: '#000000',
                                pointSize: 4,
                                xLabelFormat: xLabelFormat,
                                xLableAngle: 60,
                                resize: 'true',
                                parseTime: false,
                                hideHover: 'auto',
                                hoverCallback: hoverCallback
                            });
                        });
                });
    }

    CalcMaxSimple(graphObj) {

        var filteredArray = [];

        graphObj.map(function (element) {

            var keys = Object.getOwnPropertyNames(element);

            keys.forEach(function (key) {
                if (key !== 'x') {
                    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    filteredArray.push(element[key]);
                }
            });

        });

        var max = Math.max.apply(Math, filteredArray);

        var roundedMax = Math.ceil(max / 10) * 10;

        return roundedMax;
    }


}
