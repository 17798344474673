







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { IConsumptionReportsRequestParams } from "@/interfaces/consumptionReportsRequestParams";
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../../../utilities/GtagHelper';

import moment from 'moment';

const GasAccounts = namespace("GasAccounts");
const EnergyCostReports = namespace("EnergyCostReports");
const Auth = namespace("Auth");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepBulkEnergyCostReportsForAccount extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private EndDate = '';

    private processing = false;

    private intervalHandle: (number | undefined) = undefined;
    private estimatedMaxDownloadSeconds = 20;
    private downloadProgressCounter = 0;

    private GtagHelper = new GtagHelper();

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @EnergyCostReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @EnergyCostReports.Action
    private submitEnergyCostReportsRequest!: (params: IConsumptionReportsRequestParams) => Promise<void>;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    private consumptionReportsRequestParams: IConsumptionReportsRequestParams =
    {
        userId: null,
        accountNumber: null,
        siteRef: null,
        startDate: null,
        endDate: null,
        includeAllSites: false,
    }

    private FileProcess = new FileProcess();

    onDownload(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                return;
            }
        
            this.showDownloadIndictator();

            try {
                this.consumptionReportsRequestParams.userId = this.getUserId;
                this.consumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
                this.consumptionReportsRequestParams.startDate = this.StartDate;
                this.consumptionReportsRequestParams.endDate = this.EndDate;
                this.consumptionReportsRequestParams.includeAllSites = true;

                this.submitEnergyCostReportsRequest(this.consumptionReportsRequestParams).then(() => {

                    this.resetDownloadIndicator();
                    var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);

                    if (success) {
                        this.GtagHelper.Event("LumiDownloadEnergyCostReport", this.getUserId);
                    }

                }, (error) => {
                    this.resetDownloadIndicator();
                })

            } catch (error) {
                this.resetDownloadIndicator();
            }
        });
    }

    private showDownloadIndictator() {
        this.processing = true;
        this.intervalHandle = setInterval(() => {
            if (this.processing && this.downloadProgressCounter < this.estimatedMaxDownloadSeconds) {
                this.downloadProgressCounter++;
            }
        }, 1000);
    }

    private resetDownloadIndicator(): void {
        this.processing = false;
        this.downloadProgressCounter = 0;
        clearInterval(this.intervalHandle);
        this.intervalHandle = undefined;
    }

    beforeMount() {
        const dict = {
            custom: {
                energyCostReportDateFrom: {
                    required: 'Report date from is required'
                },
                energyCostReportDateTo: {
                    required: 'Report date to is required'
                }

            }
        };

        this.$validator.localize('en', dict);
    }

    mounted(): void {
        this.setMprnSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
    }

    beforeDestroy(): void {
        this.setMprnSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
    }

}
