export class FileProcess {

    public downlaodFile(data: Blob | null, fileName: string | null): boolean {
        if (data == null) {
            return false;
        }

        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((data) as Blob, fileName as string);
            return true;
        } else {
            const fileURL = window.URL.createObjectURL((data) as Blob);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
            return true;
        }

        return false;
    }
}