




























import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import { namespace } from 'vuex-class';

const PowerAccounts = namespace("PowerAccounts");

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepConsumptionReportsPower extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: String, default: '' }) readonly next_step_id!: string;

    @PowerAccounts.Getter
    private isHalfHourly!: boolean;

    private selectedOption = 0;
    private processing = false;
    private visible = true;        
 
    setVariant(value: number) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: number) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", this.selectedOption);            
    }       
}
