














import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import { namespace } from 'vuex-class';
const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepConsumptionReportsOptions extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: '' }) readonly next_step_id!: string[];
    private selectedOption = 0;
    private isAdmin = false;
    private showOption = true;
    private moreThanOneAccount = false;
    private watchIsSass: any;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Getter
    private isSams!: boolean;

    setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: any) {
        this.selectedOption = value;
        this.$emit("selectedLatestInvoicesOption", value);
    }

    created() {
        this.isAdmin = this.isGazpromAdmin;
        this.moreThanOneAccount = this.getUserAccountsCount > 1;
    }

    mounted() {
        try {
            this.watchIsSass = this.$store.watch(
                (state, getters) => getters["GasAccounts/isSass"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }

                    if (newValue == true) {
                        this.showOption = false;
                        this.$emit("selectedLatestInvoicesOption", 26);
                    } else {
                        this.showOption = true;
                    }

                }
                , { immediate: true });
        } catch (e) {

            console.error(e);
        }

    }

}
