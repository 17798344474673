













import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepConsumptionReportsOptions extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    private selectedOption = 0;

    setVariant(value: number) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: number) {
        this.selectedOption = value;
        this.$emit("selectedLatestInvoicesOption", value);
    }
}
