














import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import { namespace } from 'vuex-class';
const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepConsumptionReportsOptions extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: [] }) readonly next_step_id!: string[];
    private selectedOption = 0;
    private moreThanOneAccount = false;
    private isAdmin = false;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Getter
    private isSams!: boolean;

    setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: any) {
        this.selectedOption = value;
        this.$emit("selectedLatestInvoicesOption", value);
    }

    created() {
        this.moreThanOneAccount = this.getUserAccountsCount > 1;
        this.isAdmin = this.isGazpromAdmin;
    }

    //Not needed anymore
    //Leaving code in for reference as it may be needed later
    //mounted(): void {
    //    if (this.isSass) {
    //        this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
    //            if (this.isSass && (collapseId == this.id && isJustShown == true || collapseId == "step-5" && isJustShown == false)) {
    //                this.onSelectedOptionChanged(5);
    //                this.$root.$emit('bv::toggle::collapse', 'step-5');
    //            }
    //        })
    //    }
    //}

}
