











































































































import { Component, Watch, Vue } from "vue-property-decorator";
import ClipLoader from '@/components/elements/ClipLoader.vue';
import BasePage from "@/components/pages/BasePage.vue";
import BaseWizard from "@/components/wizard/BaseWizard.vue"
import WizardStepConsumptionReportsGas from "@/components/wizard/consumption/gas/WizardStepConsumptionReportsGas.vue"
import WizardStepConsumptionReportsOptions from "@/components/wizard/consumption/gas/WizardStepConsumptionReportsOptions.vue"
import WizardStepConsumptionReportsForAgreement from "@/components/wizard/consumption/gas/WizardStepConsumptionReportsForAgreement.vue"
import WizardStepBulkConsumptionReportsForAccount from "@/components/wizard/consumption/gas/WizardStepBulkConsumptionReportsForAccount.vue"
import WizardStepBulkConsumptionReportsForAccounts from "@/components/wizard/consumption/gas/WizardStepBulkConsumptionReportsForAccounts.vue"


//EnergyCost imports
import WizardStepEnergyCostReportsOptions from "@/components/wizard/consumption/gas/energycost/WizardStepEnergyCostReportsOptions.vue";
import WizardStepEnergyCostReportsForAgreement from "@/components/wizard/consumption/gas/energycost/WizardStepEnergyCostReportsForAgreement.vue";
import WizardStepBulkEnergyCostReportsForAccount from "@/components/wizard/consumption/gas/energycost/WizardStepBulkEnergyCostReportsForAccount.vue";

//CostOfGas imports
import WizardStepCostOfGasReports from "@/components/wizard/consumption/gas/costofgas/WizardStepCostOfGasReports.vue";

//AMR Smart Metering imports
import WizardStepAmrSmartMetering from "@/components/wizard/consumption/gas/amrsmartmetering/WizardStepAmrSmartMetering.vue";
import WizardStepAmrSmartMeteringOptions from "@/components/wizard/consumption/gas/amrsmartmetering/WizardStepAmrSmartMeteringOptions.vue";
import WizardStepAmrSmartMeteringReport from "@/components/wizard/consumption/gas/amrsmartmetering/WizardStepAmrSmartMeteringReport.vue";
import WizardStepAmrSmartMeteringReportsForAccounts from "@/components/wizard/consumption/gas/amrsmartmetering/WizardStepAmrSmartMeteringReportsForAccounts.vue";

//DM Consumption reports
import WizardStepDMConsumptionReports from "@/components/wizard/consumption/gas/dailymeter/WizardStepDMConsumptionReports.vue";

//Daily Consumption reports
import WizardStepDailyConsumptionReports from "@/components/wizard/consumption/gas/dailymeter/WizardStepDailyConsumptionReports.vue";

//Power imports
import WizardStepConsumptionReportsPower from "@/components/wizard/consumption/power/WizardStepConsumptionReportsPower.vue";
import WizardStepConsumptionReportsForDailyConsumption from "@/components/wizard/consumption/power/WizardStepConsumptionReportsForDailyConsumption.vue";
import WizardStepConsumptionReportsForHalfHourlyConsumption from "@/components/wizard/consumption/power/WizardStepConsumptionReportsForHalfHourlyConsumption.vue";
//Power imports - END

import { JourneyNode } from '@/utilities/JourneyNode';
import { namespace } from 'vuex-class';

const Products = namespace("Products");
const ConsumptionWizard = namespace("ConsumptionWizard");
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");
const PowerAccounts = namespace("PowerAccounts");
const ConsumptionReportsPower = namespace("ConsumptionReportsPower");

@Component({
    components: {
        BasePage,
        ClipLoader,
        BaseWizard,
        WizardStepConsumptionReportsGas,
        WizardStepConsumptionReportsOptions,
        WizardStepConsumptionReportsForAgreement,
        WizardStepBulkConsumptionReportsForAccounts,
        WizardStepBulkConsumptionReportsForAccount,
        WizardStepEnergyCostReportsOptions,
        WizardStepEnergyCostReportsForAgreement,
        WizardStepBulkEnergyCostReportsForAccount,
        WizardStepCostOfGasReports,
        WizardStepDMConsumptionReports,
        WizardStepDailyConsumptionReports,
        WizardStepAmrSmartMetering,
        WizardStepAmrSmartMeteringOptions,
        WizardStepAmrSmartMeteringReport,
        WizardStepAmrSmartMeteringReportsForAccounts,
        WizardStepConsumptionReportsPower,
        WizardStepConsumptionReportsForDailyConsumption,
        WizardStepConsumptionReportsForHalfHourlyConsumption
    },
})
export default class Consumption extends Vue {
    private isExactActive = false;
    private watchProductSelection: any;
    private watchGasAccountSelection: any;
    private watchPowerAccountSelection: any;
    private watchGasAgreementSelection: any;
    private watchPowerAccountSiteSelection: any;
    private componentKey = 0;
    private transitionName = 'fade';
    private onSingleAccountAndOnSingleSite = false;
    private moreThanOneAccount = true;
    private showPowerDecisionTreeOrMessage = false;


    private next_step_gas_id = ["step-3", "step-4", "step-5"];
    private next_step_power_id = ["step-1", "step-2"];

    @Watch('$route.path', { immediate: true, deep: true })
    onUrlChange(to: string, from: string) {
        if (to == null || to == undefined) {
            this.transitionName = 'fade';
            return;
        }
        const toDepth = to.split('/').length
        const fromDepth = from?.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    @ConsumptionWizard.Getter
    private getRootJourney!: JourneyNode | null;

    @ConsumptionWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @ConsumptionWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    @ConsumptionWizard.Action
    private appendJourneyToJourneyNode!: (option: number) => Promise<void>;

    @Products.Getter
    private getSelectedProduct!: string;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @PowerAccounts.Getter
    private getSelectedAccount!: string;
    
    @PowerAccounts.Getter
    private isHalfHourly!: boolean;

    //Power - END

    public async onSelectedInvoicingOption(option: number) {
        await this.initJourneyNode(option);
    }

    public async onSelectedLatestInvoicesOption(option: number) {
        await this.appendJourneyToJourneyNode(option);
    }

    public findJourney(option: number): boolean {
        let root = this.getRootJourney;
        if (root !== null) {
            while (root !== null) {
                if ((root as JourneyNode).Value == option) {
                    return true;
                }
                root = (root as JourneyNode).Next;
            }
        }
        return false;
    }

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
        this.resetJourneyNode();

        try {

            this.watchGasAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
            });

            this.watchPowerAccountSelection = this.$store.watch((state, getters) => getters["PowerAccounts/getSelectedGroupAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
            });

            this.watchProductSelection = this.$store.watch((state, getters) => getters["Products/getSelectedProduct"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
                this.onSingleAccountAndOnSingleSite = this.isSass;
            }, { immediate: true });

            this.watchGasAgreementSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }

                if (this.findJourney(21)) { //21 is accordion id for dm consumption
                    this.resetJourneyNode();
                    this.forceRerender();
                }

                if (this.findJourney(24)) { //24 is accordion id for amr chart
                    this.forceRerender();
                }
            });

            this.watchPowerAccountSiteSelection = this.$store.watch((state, getters) => getters["PowerAccounts/getSelectedMpan"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                if (this.getSelectedProduct == "Power") {
                    this.forceRerender();
                }
            });

            this.showPowerDecisionTreeOrMessage = true;

        } catch (e) {

            console.error(e);
        }

        this.onSingleAccountAndOnSingleSite = this.isSass;
        this.moreThanOneAccount = this.getUserAccountsCount > 1;
    }

    forceRerender() {
        this.componentKey += 1
    }

    beforeDestroy() {
        this.watchProductSelection();
        this.watchGasAccountSelection();
        this.watchPowerAccountSelection();
        this.watchGasAgreementSelection();
        this.watchPowerAccountSiteSelection();
    }
}
