



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { IConsumptionReportsRequestParams } from "@/interfaces/consumptionReportsRequestParams";
import { IContractDetails } from '@/interfaces/contractDetails';
import { FileProcess } from "@/utilities/FileProcess";
import ClipLoader from '@/components/elements/ClipLoader.vue';
import { GtagHelper } from '../../../../../utilities/GtagHelper';

import moment from 'moment';

const GasAccounts = namespace("GasAccounts");
const EnergyCostReports = namespace("EnergyCostReports");
const Auth = namespace("Auth");
const Panel = namespace("Panel");

@Component({
    components: {
        WizardBaseStep,
        ClipLoader
    }
})


export default class WizardStepEnergyCostReportsForAgreement extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private EndDate = '';

    private loading = true;
    private processing = false;

    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 20;
    private downloadProgressCounter = 0;
    public unwatch: any;

    private GtagHelper = new GtagHelper();

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private isLargeUser!: boolean;

    @Panel.Getter
    private ContractDetails!: IContractDetails | null;

    @Panel.Action
    private getContractDetails!: (userData: any) => Promise<any>;

    @EnergyCostReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @EnergyCostReports.Action
    private submitEnergyCostReportsRequest!: (params: IConsumptionReportsRequestParams) => Promise<void>;

    private consumptionReportsRequestParams: IConsumptionReportsRequestParams =
        {
        userId : null,
        accountNumber: null,
        siteRef: null,
        startDate: null,
        endDate: null,
        includeAllSites: false,
    }

    private FileProcess = new FileProcess();

    onDownload(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.processing = false;
                return;
            }
        
            this.showDownloadIndictator();

            try {
                this.consumptionReportsRequestParams.userId = this.getUserId;
                this.consumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
                this.consumptionReportsRequestParams.siteRef = this.getSelectedSiteRefNumber;
                this.consumptionReportsRequestParams.startDate = this.StartDate;
                this.consumptionReportsRequestParams.endDate = this.EndDate;
                this.consumptionReportsRequestParams.includeAllSites = false;

                this.submitEnergyCostReportsRequest(this.consumptionReportsRequestParams).then(() => {
                    this.processing = false;
                    this.downloadProgressCounter = 0;
                    var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);

                    if (success) {
                        this.GtagHelper.Event("LumiDownloadEnergyCostReport", this.getUserId);
                    }

                }, (error) => {
                    this.processing = false;
                    this.downloadProgressCounter = 0;
                    console.error(error.response.data);
                })

            } catch (error) {
                this.processing = false;
                this.downloadProgressCounter = 0;
                console.log(error.response.data);
            }
        });
    }

    private showDownloadIndictator() {
        this.processing = true;
        this.intervalHandle = setInterval(() => {
            if (this.processing && this.downloadProgressCounter < this.estimatedMaxDownloadSeconds) {
                this.downloadProgressCounter++;
            }
        }, 1000);
    }

    beforeMount() {
        const dict = {
            custom: {
                energyCostReportDateFrom: {
                    required: 'Report date from is required'
                },
                energyCostReportDateTo: {
                    required: 'Report date to is required'
                }

            }
        };

        this.$validator.localize('en', dict);
    }

    async created() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedContractId"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }
                    this.loading = true;
                    const siteRefNumber = this.getSelectedSiteRefNumber;
                    const contractId = this.getSelectedContractId;
                    if (siteRefNumber !== null && contractId !== 0) {
                        this.getContractDetails({ UserId: this.getUserId, AccountNumber: this.getSelectedAccount, SiteRefNumber: siteRefNumber, ContractId: contractId, isLargeUser: this.isLargeUser }).then(() => {
                            this.loading = false;
                        }).catch(reason => {
                            this.loading = false;
                            console.error(reason);
                        });
                    }
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }

    }

    beforeDestroy() {
        this.unwatch();
    }
}
