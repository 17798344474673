
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DateValidation } from "@/utilities/DateValidation";
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"    
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../../../utilities/GtagHelper';
import HelpAndSupport from "@/components/HelpAndSupport.vue"
import { IDeliveryAgreement } from "@/interfaces/deliveryAgreement";

const Auth = namespace("Auth");
const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const AmrSmartMeteringReports = namespace("AmrSmartMeteringReports");


@Component({
    components: {
        WizardBaseStep,
        HelpAndSupport
    }
})


export default class WizardStepAmrSmartMeteringReport extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private processing = false;
    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;
    private loading = false;
    private StartDate = '';
    private EndDate = '';
    private reportStart = '';
    private reportEnd = '';
    private startMonthErrorMessage: string | null = null;
    private endMonthErrorMessage: string | null = null;
    private errorMessage: string | null = null;
    private showDownloadReport = false;
    private selectedOption = 0;
    private alignment = 'center';

    private validator = new DateValidation();

    private now = new Date();
    private today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    private maxDate = new Date(this.today);
    private minDate = new Date(this.now.getFullYear() - 1, this.now.getMonth(), this.now.getDate());

    private GtagHelper = new GtagHelper();

    @Auth.Getter
    private getUserId!: string;

    @Products.Getter
    private getSelectedProduct!: string;

    @PowerAccounts.Getter
    private getSelectedAccount_Power!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getUserDeliveryAgreements!: Array<IDeliveryAgreement>;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Action
    private fetchDeliveryAgreementsForAccount!: (params: { userId: string, accountNumber: string,  }) => Promise<void>;
    
    @AmrSmartMeteringReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };
 
    @AmrSmartMeteringReports.Action
    private downloadBulkReport_gas!: (params: { userId: string, accountNum: string, startDate: string, endDate: string, mprns: Array<IDeliveryAgreement> }) => Promise<void>;

    @AmrSmartMeteringReports.Action
    private downloadBulkReport_power!: (params: { userId: string, account: string, startDate: string, endDate: string, mpans: Array<string> }) => Promise<void>;
    
    private FileProcess = new FileProcess();

    private onDownload(): void {
        this.$validator.reset;
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.processing = false;
                return;
            }
            else {
                this.reportStart = this.StartDate
                this.reportEnd = this.EndDate
                this.processing = true;
                this.onAmrSmartMeteringDownload()

            }
        });
    }

    private async onAmrSmartMeteringDownload(): Promise<void> {
        try
        {
            if (this.getSelectedProduct == "Gas") {
                var accountParams = {
                    userId: this.getUserId,
                    accountNumber: this.getSelectedAccount
                }
                await this.fetchDeliveryAgreementsForAccount(accountParams);
                var gasParams = {
                    userId: this.getUserId,
                    accountNum: this.getSelectedAccount,
                    startDate: this.reportStart,
                    endDate: this.reportEnd,
                    mprns: this.getUserDeliveryAgreements
                }
                this.showDownloadIndicator();
                await this.downloadBulkReport_gas(gasParams);
            }

            var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
            if (success) {
                this.GtagHelper.Event("LumiDownloadAmrSmartMeteringReportBulk", this.getUserId);
                this.processing = false;
            }
        }
        catch (e)
        {
            this.processing = false;
            console.error(e);
        }
        finally {
            this.processing = false;
            this.clearInterval();
        }
    }

    private clearInterval() {
        if (this.intervalHandle) {
            clearInterval(this.intervalHandle)
            this.intervalHandle = null;
            this.downloadProgressCounter = 0;
        }
    }

    private showDownloadIndicator() {
        this.downloadProgressCounter = 0;
        this.intervalHandle = setInterval(() => {
            if (this.processing && this.downloadProgressCounter < this.estimatedMaxDownloadSeconds) {
                this.downloadProgressCounter++;
            }
        }, 1000);
    }

    mounted(): void {
        this.setMprnSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
    }

    beforeDestroy(): void {
        this.setMprnSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
    }
}
