import moment from 'moment';

export class DateValidation {
    private StartMonth: string | null;
    private EndMonth: string | null;
    private StartMonthErrorMessage: string | null ;
    private EndMonthErrorMessage: string | null ;
    private ErrorMessage: string | null;
    private DayDiff: boolean | null;

    constructor() {
        this.StartMonth = null;
        this.EndMonth = null;
        this.StartMonthErrorMessage = null;
        this.EndMonthErrorMessage = null;
        this.ErrorMessage = null;
        this.DayDiff = null;
    }

    public set setStartMonth(startMonth: string | null) {
        this.StartMonth = startMonth;
    }

    public set setEndMonth(endMonth: string | null) {
        this.EndMonth = endMonth;
    }

    public set setDayDiff(dayDiff: boolean | null) {
        this.DayDiff = dayDiff;
    }

    public get getStartMonth(): string | null {
        return this.StartMonth;
    }

    public get getEndMonth(): string | null {
        return this.EndMonth;
    }

    public get getStartMonthErrorMessage(): string | null {
        return this.StartMonthErrorMessage;
    }

    public get getEndMonthErrorMessage(): string | null {
        return this.EndMonthErrorMessage;
    }

    public get getErrorMessage(): string | null {
        return this.ErrorMessage;
    }

    public validate(): boolean {
        this.reset();

        if (this.DayDiff) {
            if (this.StartMonth == null || this.StartMonth == '') {
                this.StartMonthErrorMessage = `Start date is required`;
                return false;
            }

            if (this.EndMonth == null || this.EndMonth == '') {
                this.EndMonthErrorMessage = `End date is required`;
                return false;
            }

            if (this.EndMonth >= this.StartMonth) {
                this.reset();
            }
            else {
                this.ErrorMessage = `Start date must be before or the same as the end date`
                return false;
            }

            if (this.daysDifference() > 31) {
                this.ErrorMessage = 'Date range cannot be greater than 31 days';
                return false;
            }
        }
        else {
            if (this.StartMonth == null || this.StartMonth == '') {
                this.StartMonthErrorMessage = `Start month and year required`;
                return false;
            }

            if (this.EndMonth == null || this.EndMonth == '') {
                this.EndMonthErrorMessage = `End month and year required`;
                return false;
            }

            if (this.EndMonth >= this.StartMonth) {
                this.reset();
            }
            else {
                this.ErrorMessage = `Start month must be before or the same as the end month`
                return false;
            }

            if (this.monthsDifference() >= 14) {
                this.ErrorMessage = `Your date selections cannot exceed 13 months`;
                return false;
            }
        }
        return true;
    }


    public reset(): void {
        this.ErrorMessage = null;
        this.StartMonthErrorMessage = null;
        this.EndMonthErrorMessage = null;
    }

    private monthsDifference(): number {
        const monthDifference = moment(new Date(this.EndMonth as string)).diff(new Date(this.StartMonth as string), 'months', true);
        return monthDifference;
    }

    private daysDifference(): number {
        const dayDifference = moment(new Date(this.EndMonth as string)).diff(new Date(this.StartMonth as string), 'days', true);
        return dayDifference;
    }
}