














































































import { Component, Vue } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { namespace } from 'vuex-class';
import VueMonthlyPicker from 'vue-monthly-picker';
import { IAmrSmartMeterReportDetails } from '@/interfaces/amrSmartMeterReport';
import { IAmrSmartMeterChartDetails } from '@/interfaces/amrSmartMeterChart';
import moment from 'moment';
import * as jQuery from 'jquery';

const AmrSmartMeteringReports = namespace("AmrSmartMeteringReports");
const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const PowerConsumptionReports = namespace("ConsumptionReportsPower");
const Auth = namespace("Auth");
const Products = namespace("Products");

@Component({
    components: {
        apexchart: VueApexCharts,
        VueMonthlyPicker
    }
})
export default class WizardStepAmrSmartMeteringChart extends Vue {

    @Auth.Getter
    private getUserId!: string;

    @Products.Getter
    private getSelectedProduct!: string;

    @PowerAccounts.Getter
    private getSelectedAccount_Power!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @PowerAccounts.Getter
    private getSelectedMpan!: string;

    @GasAccounts.Getter
    private getSelectedMprn!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @AmrSmartMeteringReports.Getter
    private AmrChartData!: string;

    @AmrSmartMeteringReports.Getter
    private YearlyAmrChartData!: string;

    @AmrSmartMeteringReports.Getter
    private LatestAmrChartDataDate!: string;

    @AmrSmartMeteringReports.Getter
    private ContractStartDate!: string;

    @AmrSmartMeteringReports.Getter
    private ContractEndDate!: string;

    @AmrSmartMeteringReports.Action
    private getAmrChartData_gas!: (params: { userId: string, mprn: string, siteRefNum: string, accountNum: string }) => Promise<void>;

    @AmrSmartMeteringReports.Action
    private getAmrChartData_power!: (params: { userId: string, mpan: string, account: string }) => Promise<void>;

    @AmrSmartMeteringReports.Action
    private aggregateAmrChartData_gas!: () => Promise<void>;

    @AmrSmartMeteringReports.Action
    private getContractDates!: (params: { userId: string, mprn: string, siteRefNum: string, accountNum: string }) => Promise<void>;

    public unwatch: any;

    private selectedOption = 2;

    private loading = true;

    private graphProcessing = false;
    private showGraph = false;
    private missingChartData = false;
    private emptyChartData = false;
    private consumptionPeriodTotal = 0;

    private series;
    private chartOptions;
    private preFilteredChartData;
    private yearChartData;
    private yearConsumption = 0;
    private filterText = "";
    private selectedFilterText = "";
    private yAxisLabel = "";
    private selectedMeter = "";
    private decimalPlaces = 3;
    private yAxisDecimalPlaces = 2;
    private alignment = 'center';

    private now = new Date();
    private today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    private maxDate = new Date(this.today);
    private minDate = new Date(this.now.getFullYear() - 1, this.now.getMonth(), this.now.getDate());
    private minMonth = moment(new Date(this.now.getFullYear() - 1, this.now.getMonth(), 1));
    private maxMonth = moment(String(new Date(this.maxDate))).format('yyyy-MM');
    private intervals
    private hhTimes = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"];
    private monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    private rangeEnd;
    private resultsReturned = false;

    async created() {
        try {
            if (this.getSelectedProduct == "Gas") {
                this.unwatch = this.$store.watch(
                    (state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"],
                    (newValue, oldValue) => {
                        if (newValue === null || newValue === oldValue) {
                            return;
                        }
                        this.graphProcessing = true;
                        this.decimalPlaces = 2;
                        this.preFilteredChartData = '';
                        this.yAxisLabel = "Consumption";
                        this.selectedMeter = this.getSelectedMprn;

                        var gasParams = {
                            userId: this.getUserId,
                            siteRefNum: this.getSelectedSiteRefNumber,
                            mprn: this.getSelectedMprn,
                            accountNum: this.getSelectedAccount
                        }

                        this.getAmrChartData_gas(gasParams).then(() => {
                            if (this.AmrChartData.length > 0) {
                                this.getContractDates(gasParams).then(() => {
                                    this.aggregateAmrChartData_gas().then(() => {
                                        this.yearChartData = JSON.parse(this.YearlyAmrChartData);
                                        this.yearConsumption = this.yearChartData.reduce((total, item) => {
                                            return total += item.y;
                                        }, 0);
                                        this.setLatestAmrDataDate();
                                        this.emitAmrChartMessage(true);
                                        this.FilterChart();
                                    });
                                });
                            } else {
                                this.graphProcessing = false;
                                this.showGraph = false;
                            }
                        }).catch(reason => {
                            this.graphProcessing = false;
                            this.showGraph = false;
                            console.error(reason);
                        });
                    }, { immediate: true });
            }
            else {
                this.unwatch = this.$store.watch(
                    (state, getters) => getters["PowerAccounts/getSelectedGroupAccount"],
                    (newValue, oldValue) => {
                        if (newValue === null || newValue === oldValue) {
                            return;
                        }
                        this.graphProcessing = true;
                        this.decimalPlaces = 3;
                        this.preFilteredChartData = '';
                        this.yAxisLabel = "kWh";
                        this.selectedMeter = this.getSelectedMpan;

                        var powerParams = {
                            userId: this.getUserId,
                            account: this.getSelectedAccount_Power,
                            mpan: this.getSelectedMpan
                        }

                        this.getAmrChartData_power(powerParams).then(() => {
                            if (this.AmrChartData.length > 0) {
                                this.aggregateAmrChartData_gas().then(() => {
                                    this.yearChartData = JSON.parse(this.YearlyAmrChartData);
                                    this.yearConsumption = this.yearChartData.reduce((total, item) => {
                                        return total += item.y;
                                    }, 0);
                                    this.setLatestAmrDataDate();
                                    this.emitAmrChartMessage(true);
                                    this.FilterChart();
                                });
                            } else {
                                this.graphProcessing = false;
                                this.showGraph = false;
                            }
                        }).catch(reason => {
                            this.graphProcessing = false;
                            this.showGraph = false;
                            console.error(reason);
                        });
                    }, { immediate: true });
            }
        } catch (e) {
            this.graphProcessing = false;
            this.showGraph = false;
            console.error(e);
        }
    }

    beforeDestroy() {
        this.unwatch();
    }


    setLatestAmrDataDate() {
        this.preFilteredChartData = JSON.parse(this.AmrChartData);

        var latestAmrDataDate = this.LatestAmrChartDataDate;

        if (this.selectedOption == 2) {
            this.filterText = moment(String(new Date(latestAmrDataDate))).format('yyyy-MM-');
        }
        else if (this.selectedOption == 3) {
            this.filterText = moment(String(new Date(latestAmrDataDate))).format('yyyy-MM-DD');
        }
    }

    emitAmrChartMessage(value) {
        this.$emit('amrchartdata', value);
    }

    setVariant(value: number) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: number) {
        if (value < this.selectedOption) {
            this.selectedOption = value;
            if (this.selectedOption == 1) {
                this.FilterChart();
            }
            else if (this.selectedOption == 2) {
                this.filterText = this.filterText.substr(0, 7);
                this.FilterChart();
            }
        }
        else {
            this.selectedOption = value;
            this.setLatestAmrDataDate();
            this.FilterChart();
        }
    }

    async setMonthFilter(date: any) {
        if (date !== null && date !== '') {
            this.filterText = date.format('YYYY-MM-');
            this.FilterChart();
        }
    }

    increaseDate() {
        var currentDate = new Date(this.filterText);
        if (currentDate < this.maxDate) {
            if (this.selectedOption == 3) {
                this.filterText = moment(String(new Date(currentDate.setDate(currentDate.getDate() + 1)))).format('yyyy-MM-DD');
            }
            else if (this.selectedOption == 2) {
                this.filterText = moment(String(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))).format('yyyy-MM');
            }
            this.FilterChart();
        }
    }

    decreaseDate() {
        var currentDate = new Date(this.filterText);
        if (currentDate > this.minDate) {
            if (this.selectedOption == 3) {
                this.filterText = moment(String(new Date(currentDate.setDate(currentDate.getDate() - 1)))).format('yyyy-MM-DD');
            }
            else if (this.selectedOption == 2) {
                this.filterText = moment(String(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))).format('yyyy-MM');
            }
            this.FilterChart();
        }
    }

    FilterChart() {
        if (this.selectedOption > 1) {
            const filteredChartData = this.preFilteredChartData.filter(item =>
                item.x.includes(this.filterText)
            );

            if (filteredChartData.length > 0) {

                this.emptyChartData = false;

                this.consumptionPeriodTotal = filteredChartData.reduce((total, item) => {
                    return total += item.y;
                }, 0);

                /*var nullChartData = filteredChartData.filter(function (item) {
                    if (item.y == null) return item.x
                });

                nullChartData = nullChartData.filter(function (item) {
                    var now = new Date();
                    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
                    if (new Date(item.x) < today) return item.x
                });

                nullChartData = nullChartData.filter(function (item) {
                    var now = new Date();
                    var earlyDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
                    if (new Date(item.x) > earlyDate) return item.x
                });

                if (nullChartData.length > 0) {
                    this.missingChartData = true;
                }
                else {
                    this.missingChartData = false;
                }*/

                if (this.selectedOption == 3) {
                    this.intervals = this.hhTimes;
                    this.GenerateChart(filteredChartData);
                    this.graphProcessing = false;
                    this.showGraph = true;
                }
                else if (this.selectedOption == 2) {
                    const monthAggregatedData = Object.values(filteredChartData.reduce((aggregated, item) => {
                        const x = item.x.substr(0, 10);
                        const key = `${x}`;
                        const y = (aggregated[key]?.y + item.y) || item.y;

                        aggregated[key] = { x, y };
                        return aggregated;
                    }, {}));

                    this.rangeEnd = moment(String(this.filterText)).daysInMonth();
                    this.GenerateChartMonthly(monthAggregatedData);
                    this.graphProcessing = false;
                    this.showGraph = true;
                }
                else {
                    this.graphProcessing = false;
                    this.missingChartData = false;
                }

            }
            else {
                this.intervals = [""];
                this.GenerateChart(filteredChartData);
                this.emptyChartData = true;
                this.missingChartData = false;
                this.graphProcessing = false;
                this.showGraph = true;
            }
        }
        else {
            this.rangeEnd = this.yearChartData.length;
            this.GenerateChartYearly(this.yearChartData);
            this.consumptionPeriodTotal = this.yearConsumption
            this.graphProcessing = false;
            this.showGraph = true;
        }
    }

    GenerateChart(chartdata: any) {

        this.series = [{ name: this.selectedMeter, data: chartdata }];

        this.chartOptions = { // ApexCharts options
            chart: {
                id: "amrchart",
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                defaultLocale: 'en',
            },
            colors: ["#00558e"],
            xaxis: {
                type: 'category',
                tickPlacement: 'on',
                overwriteCategories: this.intervals,
                labels: {
                    rotate: 0,
                },
            },
            yaxis: {
                showAlways: false,
                showForNullSeries: false,
                forceNiceScale: true,
                decimalsInFloat: this.yAxisDecimalPlaces,
                labels: {
                    show: true,
                    style: {
                        colors: [],
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                },
                title: {
                    text: this.yAxisLabel,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                shared: false,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                fillSeriesColor: false,
                theme: false,
                custom: ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                }) =>
                    this.customTooltipDaily({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                    }),
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                marker: {
                    show: false,
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            noData: {
                text: "Your data for this time period is currently unavailable. We apologise for any inconvenience.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                }
            }
        };
    }

    GenerateChartMonthly(chartdata: any) {

        this.series = [{ name: this.selectedMeter, data: chartdata }];

        this.chartOptions = { // ApexCharts options
            chart: {
                id: "amrchart",
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                defaultLocale: 'en',
                events: {
                    click: (event) => {
                        if (event.detail === 2 && this.selectedOption < 3 && this.selectedFilterText != '') {
                            this.selectedOption = this.selectedOption + 1;
                            this.filterText = this.selectedFilterText;
                            this.selectedFilterText = '';
                            this.FilterChart();
                        }
                    },
                    dataPointSelection: (event, chartContext, config) => {
                        this.selectedFilterText = config.w.config.series[0].data[config.dataPointIndex].x;
                    }
                }
            },
            annotations: {
                xaxis: [
                    {
                        x: this.isContractStartDate(this.ContractStartDate),
                        borderColor: '#00558e',
                        label: {
                            style: {
                                color: '#fff',
                                background: '#00558e',
                                opacity: 0.1,
                            },
                            text: 'Contract Start Date - ' + moment(this.ContractStartDate).format("DD/MM/yyyy"),
                        }
                    },
                    {
                        x: this.isContractStartDate(this.ContractEndDate),
                        borderColor: '#00558e',
                        label: {
                            style: {
                                color: '#fff',
                                background: '#00558e',
                                opacity: 0.1,
                            },
                            text: 'Contract End Date - ' + moment(this.ContractEndDate).format("DD/MM/yyyy"),
                        }
                    }
                ]
            },
            colors: ["#00558e"],
            xaxis: {
                type: 'category',
                tickPlacement: 'on',
                overwriteCategories: undefined,
                min: 1,
                max: this.rangeEnd,
                stepSize: 1,
                labels: {
                    trim: false,
                    formatter: function (value) {
                        const p = moment(value).format("DD");
                        var n = +p;
                        var day = n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
                        return day;
                    },
                },
            },
            yaxis: {
                showAlways: false,
                showForNullSeries: false,
                forceNiceScale: true,
                decimalsInFloat: this.yAxisDecimalPlaces,
                labels: {
                    show: true,
                    style: {
                        colors: [],
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                },
                title: {
                    text: this.yAxisLabel,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                shared: false,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                fillSeriesColor: false,
                theme: false,
                custom: ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                }) =>
                    this.customTooltipMonthly({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                    }),
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                marker: {
                    show: false,
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            noData: {
                text: "Your data for this time period is currently unavailable. We apologise for any inconvenience.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                }
            }
        };
    }

    GenerateChartYearly(chartdata: any) {

        this.series = [{ name: this.selectedMeter, data: chartdata }];

        this.chartOptions = { // ApexCharts options
            chart: {
                id: "amrchart",
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                defaultLocale: 'en',
                selection: {
                    enabled: true,
                    type: 'x',
                    fill: {
                        color: '#24292e',
                        opacity: 0.1
                    }
                },
                events: {
                    click: (event) => {
                        if (event.detail === 2 && this.selectedOption < 3 && this.selectedFilterText != '') {
                            this.selectedOption = this.selectedOption + 1;
                            this.filterText = this.selectedFilterText;
                            this.selectedFilterText = '';
                            this.FilterChart();
                        }
                    },
                    dataPointSelection: (event, chartContext, config) => {
                        this.selectedFilterText = config.w.config.series[0].data[config.dataPointIndex].x;
                    }
                }
            },
            annotations: {
                xaxis: [
                    {
                        x: moment(this.ContractStartDate).format("MMM-YY"),
                        borderColor: '#00558e',
                        offsetX: -40,
                        label: {
                            offsetX: -25,
                            style: {
                                color: '#fff',
                                background: '#00557ad9',
                            },
                            text: 'Contract Start Date - ' + moment(this.ContractStartDate).format("DD/MM/yyyy"),
                        }
                    },
                    {
                        x: moment(this.ContractEndDate).format("MMM-YY"),
                        borderColor: '#00558e',
                        offsetX: -40,
                        label: {
                            offsetX: -25,
                            style: {
                                color: '#fff',
                                background: '#00557ad9',
                            },
                            text: 'Contract End Date - ' + moment(this.ContractEndDate).format("DD/MM/yyyy"),
                        }
                    }
                ],
            },
            colors: ["#00558e"],
            xaxis: {
                type: 'category',
                overwriteCategories: undefined,
                labels: {
                    trim: false,
                    formatter: function (value) {
                        return moment(value).format("MMM-YY");
                    },
                },
            },
            yaxis: {
                showAlways: false,
                showForNullSeries: false,
                forceNiceScale: true,
                decimalsInFloat: this.yAxisDecimalPlaces,
                labels: {
                    show: true,
                    style: {
                        colors: [],
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                },
                title: {
                    text: this.yAxisLabel,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                shared: false,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                fillSeriesColor: false,
                theme: false,
                custom: ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                }) =>
                    this.customTooltipYearly({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                    }),
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                marker: {
                    show: false,
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            noData: {
                text: "Your data for this time period is currently unavailable. We apologise for any inconvenience.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                }
            }
        };
    }

    handleSelection(data: any) {
        this.selectedFilterText = data;
    }

    private formatConsumption(value: number): string {
        if (value === null) {
            return "";
        }

        return value.toFixed(this.decimalPlaces);
    }

    customTooltipDaily({ series, seriesIndex, dataPointIndex, w }) {
        return ('<div class="card">' +
            '<span>Time: ' + w.globals.categoryLabels[dataPointIndex].substr(11, 5) + '</span><br>' +
            '<span>' + this.yAxisLabel + ': ' + series[seriesIndex][dataPointIndex].toFixed(this.decimalPlaces) + '</span>' +
            '<span>'  + '</span' +
            '<br>' +
            '</div>');
    }

    customTooltipMonthly({ series, seriesIndex, dataPointIndex, w }) {
        return ('<div class="card">' +
            '<span>Date: ' + w.globals.categoryLabels[dataPointIndex] + ' ' + moment(this.filterText).format("MMM") + '</span><br>' +
            '<span>' + this.yAxisLabel + ': ' + series[seriesIndex][dataPointIndex].toFixed(this.decimalPlaces) + '</span>' +
            '<span>' + '</span' +
            '<br>' +
            '</div>');
    }

    customTooltipYearly({ series, seriesIndex, dataPointIndex, w }) {
        return ('<div class="card">' +
            '<span>Month: ' + w.globals.categoryLabels[dataPointIndex] + '</span><br>' +
            '<span>' + this.yAxisLabel + ': ' + series[seriesIndex][dataPointIndex].toFixed(this.decimalPlaces) + '</span>' +
            '<span>' + '</span' +
            '<br>' +
            '</div>');
    }

    private isContractStartDate(value: string): string {

        if (value === null) {
            return "";
        }

        if (moment(value).format("MM-YYYY") != moment(this.filterText).format("MM-YYYY")) {
            return "";
        }

        const p = moment(value).format("DD");
        var n = +p;
        var day = n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
        return day;
    }
}
