




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../../../utilities/GtagHelper';
import { IDailyDownloadConsumptionReportRequestParams } from "@/interfaces/dailyDownloadConsumptionReportRequestParams";

const GasAccounts = namespace("GasAccounts");
const DailyConsumptionReports = namespace("DailyConsumptionReports");
const Auth = namespace("Auth");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepDailyConsumptionReports extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private reportStart = '';
    private reportEnd = '';
    private downloadError = false;
    private GtagHelper = new GtagHelper();

    private FileProcess = new FileProcess();

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getSelectedMprn!: string;

    @Auth.Getter
    private getUserId!: string;

    @DailyConsumptionReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @DailyConsumptionReports.Action
    private submitDailyDownloadConsumptionReports!: (params: { userId: string, mprn: string, siteRefNum: string, accountNum: string, startDate: string, endDate: string }) => Promise<void>;

    private onDownloadLatestReport(): void {
        this.downloadError = false;
        this.onDownloadDailyConsumptionReport();
    }

    private async onDownloadDailyConsumptionReport(): Promise<void> {
        try {

            var dailyConsumptionReportParams = {
                userId: this.getUserId,
                mprn: this.getSelectedMprn,
                siteRefNum: this.getSelectedSiteRefNumber,
                accountNum: this.getSelectedAccount,
                startDate: this.reportStart,
                endDate: this.reportEnd
            }

            await this.submitDailyDownloadConsumptionReports(dailyConsumptionReportParams);

            var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
            if (success) {
                this.GtagHelper.Event("LumiDownloadDailyConsumptionReport", this.getUserId);
            }
        } catch (e) {
            console.error(e);
            this.downloadError = true;
        }
    }
}

