




























import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import { namespace } from 'vuex-class';
import WizardStepDailyConsumptionReports from "@/components/wizard/consumption/gas/dailymeter/WizardStepDailyConsumptionReports.vue"

const GasAccounts = namespace("GasAccounts");
const DailyConsumptionReports = namespace("DailyConsumptionReports");
const Auth = namespace("Auth");


@Component({
    components: {
        WizardBaseStep,
        WizardStepDailyConsumptionReports
    }
})
export default class WizardStepConsumptionReportsGas extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: String, default: '' }) readonly next_step_id!: string;

    private selectedOption = 0;
    private processing = false;
    private visible = true;
    private watchGasAgreementSelection_Consumption: any;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @DailyConsumptionReports.Getter
    private getIsActiveDM!: false;

    @DailyConsumptionReports.Action
    private submitIsActiveDM!: (params: { userId: string, accountNum: string,  siteRef: string }) => Promise<void>;

    @Auth.Getter
    private canDownloadCogReports!: boolean;
  
    get selectedAccount() {
        return this.getSelectedAccount;
    }

    setVariant(value: number) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: number) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", this.selectedOption);            
    }

    private calculateButtonOffset(): number{
     
        let offset = (this.getIsActiveDM) ? 0 : 1

        if (!this.canDownloadCogReports) {
            offset += 2;
        }

        return offset;
    }

    mounted(): void {

        //this.submitIsActiveDM({ userId: this.getUserId, accountNum: this.getSelectedAccount, siteRef: this.getSelectedSiteRefNumber });

        try {
            this.watchGasAgreementSelection_Consumption = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }

                //this.submitIsActiveDM({ userId: this.getUserId, accountNum: this.getSelectedAccount, siteRef: this.getSelectedSiteRefNumber });
            });
        } catch (e) {

            console.error(e);
        }
    }

    beforeDestroy(): void {
        this.watchGasAgreementSelection_Consumption();
    }
}
