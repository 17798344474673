







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { IConsumptionReportsAllAccountsRequestParams } from "@/interfaces/consumptionReportsAllAccountsRequestParams";
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../../utilities/GtagHelper';

import moment from 'moment';

const Auth = namespace("Auth");
const ConsumptionReports = namespace("ConsumptionReports");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepInvoicesForAllAccounts extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private EndDate = '';

    private processing = false;

    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;

    private GtagHelper = new GtagHelper();

    @Auth.Getter
    private getUserId!: string;

    @ConsumptionReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @ConsumptionReports.Action
    private submitConsumptionReportsAllAccountsRequest!: (params: IConsumptionReportsAllAccountsRequestParams) => Promise<void>;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;

    private consumptionReportsRequestParams: IConsumptionReportsAllAccountsRequestParams =
    {
        userId: null,
        startDate: null,
        endDate: null
    }

    private FileProcess = new FileProcess();

    onDownload(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.processing = false;
                return;
            }
        
            this.showDownloadIndictator();

            try {
                this.consumptionReportsRequestParams.userId = this.getUserId;
                this.consumptionReportsRequestParams.startDate = this.StartDate;
                this.consumptionReportsRequestParams.endDate = this.EndDate;

                this.submitConsumptionReportsAllAccountsRequest(this.consumptionReportsRequestParams).then(() => {
                    this.processing = false;
                    this.downloadProgressCounter = 0;


                    var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                    if (success) {
                        this.GtagHelper.Event("LumiDownloadConsumptionReport", this.getUserId);
                    }

                }, (error) => {
                    this.processing = false;
                    this.downloadProgressCounter = 0;
                    console.error(error.response.data);
                })

            } catch (error) {
                this.processing = false;
                this.downloadProgressCounter = 0;
                console.log(error.response.data);
            }
        });
    }

    private showDownloadIndictator() {
        this.processing = true;
        this.intervalHandle = setInterval(() => {
            if (this.processing && this.downloadProgressCounter < this.estimatedMaxDownloadSeconds) {
                this.downloadProgressCounter++;
            }
        }, 1000);
    }

    beforeMount() {
        const dict = {
            custom: {
                consumptionReportDateFrom: {
                    required: 'Report date from is required'
                },
                consumptionReportDateTo: {
                    required: 'Report date to is required'
                }

            }
        };

        this.$validator.localize('en', dict);
    }

    mounted(): void {
        this.setAccountSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
        this.setMprnSelectionDisabled(true);
    }

    beforeDestroy(): void {
        this.setAccountSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
        this.setMprnSelectionDisabled(false);
    }
}
