





















































































declare var Morris: any;

import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import moment from 'moment';

import { IPowerConsumptionReportsRequestParams } from '@/interfaces/powerConsumptionReportsRequestParams';

import * as jQuery from 'jquery';

const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const PowerConsumptionReports = namespace("ConsumptionReportsPower");
const Auth = namespace("Auth");

import { FileProcess } from "@/utilities/FileProcess";

import { GtagHelper } from '../../../../utilities/GtagHelper';

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepConsumptionReportsForDailyConsumption extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private EndDate = '';

    private processing = false;

    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;
    private graphProcessing = false;
    private ShowGraph = false;

    private GtagHelper = new GtagHelper();

    @PowerAccounts.Getter
    private getSelectedAccount!: string;

    @PowerConsumptionReports.Action
    private submitGetGraphData!: (params: IPowerConsumptionReportsRequestParams) => Promise<void>;

    @PowerConsumptionReports.Action
    private submitDownlaodDailyConsumptionReport!: (params: IPowerConsumptionReportsRequestParams) => Promise<void>;

    @PowerConsumptionReports.Getter
    private getGraphData!: string;

    @Auth.Getter
    private getUserId!: string;

    @PowerAccounts.Getter
    private getSelectedMpan!: string;

    @PowerConsumptionReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    private FileProcess = new FileProcess();

    private powerConsumptionReportsRequestParams: IPowerConsumptionReportsRequestParams =
        {
            userId: null,
            accountNumber: null,
            mpan: null,
            fromDate: null,
            toDate: null,
        }

    onDownloadReport(): void {
        try {

            this.powerConsumptionReportsRequestParams.userId = this.getUserId;
            this.powerConsumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
            this.powerConsumptionReportsRequestParams.mpan = this.getSelectedMpan;
            this.powerConsumptionReportsRequestParams.fromDate = this.StartDate;
            this.powerConsumptionReportsRequestParams.toDate = this.EndDate;

            this.submitDownlaodDailyConsumptionReport(this.powerConsumptionReportsRequestParams).then(() => {
                this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
            }, (error) => {
                this.graphProcessing = false;
            })

        } catch (error) {
            console.log(error);
        }
    }

    onShow(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                return;
            }

            this.graphProcessing = true;
            this.ShowGraph = false;
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery("#chart").empty();

            try {

                this.powerConsumptionReportsRequestParams.userId = this.getUserId;
                this.powerConsumptionReportsRequestParams.accountNumber = this.getSelectedAccount;
                this.powerConsumptionReportsRequestParams.mpan = this.getSelectedMpan;
                this.powerConsumptionReportsRequestParams.fromDate = this.StartDate;
                this.powerConsumptionReportsRequestParams.toDate = this.EndDate;

                this.submitGetGraphData(this.powerConsumptionReportsRequestParams).then(() => {
                    this.GenerateGraph(JSON.parse(this.getGraphData));
                    this.graphProcessing = false;
                    this.ShowGraph = true;

                    this.GtagHelper.Event("LumiDownloadConsumptionGraph", this.getUserId);
                }, (error) => {
                    this.graphProcessing = false;
                })

            } catch (error) {
                this.processing = false;
                this.downloadProgressCounter = 0;
                this.graphProcessing = false;
            }
        });
    }

    beforeMount() {
        const dict = {
            custom: {
                dailyConsumptionReportDateFrom: {
                    required: 'Report date from is required'
                },
                dailyConsumptionReportDateTo: {
                    required: 'Report date to is required',
                    dateRangeTwelveMonth: 'Range selected is too large, please select a smaller range'
                }

            }
        };

        this.$validator.localize('en', dict);


    }

    GenerateGraph(data: any) {

        var hideAxis = data.length > 31; //restrict axis to 31 days for performance
        var graphObj = [];

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        jQuery.each(data, function (d, value) {

            var date = new Date(value["SD"]);

            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery.each(value, function (i, item) {

                var obj = {};

                if (i === 'SD' || i === 'MAX' || i === '00:00' || i === '00:30' || i === '01:00' || i === '01:30' || i === '02:00' || i === '02:30' || i === '03:00' || i === '03:30' || i === '04:00' || i === '04:30' || i === '05:00' || i === '05:30' || i === '06:00' || i === '06:30' || i === '07:00' || i === '07:30' || i === '08:00' || i === '08:30' || i === '09:00' || i === '09:30' || i === '10:00' || i === '10:30' || i === '11:00' || i === '11:30' || i === '12:00' || i === '12:30' || i === '13:00' || i === '13:30' || i === '14:00' || i === '14:30' || i === '15:00' || i === '15:30' || i === '16:00' || i === '16:30' || i === '17:00' || i === '17:30' || i === '18:00' || i === '18:30' || i === '19:00' || i === '19:30' || i === '20:00' || i === '20:30' || i === '21:00' || i === '21:30' || i === '22:00' || i === '22:30' || i === '23:00' || i === '23:30' || i === "24:00" || i === "24:30") {
                    return true;
                }

                obj = {
                    x: date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(),
                    y: item
                };

                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                graphObj.push(obj);
            });
        });

        this.LoadGraph(graphObj, hideAxis);
    }

    LoadGraph(data, hideAxis) {

        var currentDates = [];
        var max = this.CalcMaxSimple(data);
        var axes = '';
        var graphLabelUnit = "Date";

        var hoverCallback = function (index, options, content) {
            var d = options.data[index];
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            jQuery(".morris-hover").html('<div><b> ' + graphLabelUnit + ':</b> ' + d.x + '<br/><b> Total kWh:</b> ' + d.y + '</div>');
        };

        if (hideAxis) {
            axes = 'y';
        }

        var xLabelFormat = function (x) {
            var date = moment(x.label, "DD/MM/YYYY HH:mm");

            var stringDate = date.format("DD/MM/YYYY");

            if (stringDate === "Invalid date") {
                return '\xa0';
            }

            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (jQuery.inArray(stringDate, currentDates) === -1) {
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                currentDates.push(stringDate);
                return stringDate;
            }

            return '\xa0';
        };

        jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/raphael/2.1.0/raphael-min.js',
            function () {
                jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/morris.js/0.5.0/morris.min.js',
                    function () {
                        Morris.Line({
                            element: 'chart',
                            data: data,
                            axes: axes,
                            xkey: ['x'],
                            ykeys: ['y'],
                            ymax: max,
                            ymin: 0,
                            labels: ['Kwh'],
                            lineColors: ['#0b62a4'],
                            gridTextColor: '#000000',
                            pointSize: [4, 0],
                            xLabelFormat: xLabelFormat,
                            xLableAngle: 60,
                            resize: 'true',
                            parseTime: false,
                            hideHover: 'auto',
                            hoverCallback: hoverCallback
                        });
                    });
            });
    }

    CalcMaxSimple(graphObj) {

        var filteredArray = [];

        graphObj.map(function (element) {

            var keys = Object.getOwnPropertyNames(element);

            keys.forEach(function (key) {
                if (key !== 'x') {
                    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    filteredArray.push(element[key]);
                }
            });

        });

        var max = Math.max.apply(Math, filteredArray);

        var roundedMax = Math.ceil(max / 10) * 10;

        return roundedMax;
    }


}
