





































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DateValidation } from "@/utilities/DateValidation";
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"    
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../../../utilities/GtagHelper';
import { IAmrSmartMeterReportDetails } from '@/interfaces/amrSmartMeterReport';
import WizardStepAmrSmartMeteringChart from "@/components/wizard/consumption/gas/amrsmartmetering/WizardStepAmrSmartMeteringChart.vue";
import HelpAndSupport from "@/components/HelpAndSupport.vue"

import moment from 'moment';

const Auth = namespace("Auth");
const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const AmrSmartMeteringReports = namespace("AmrSmartMeteringReports");


@Component({
    components: {
        WizardBaseStep,
        WizardStepAmrSmartMeteringChart,
        HelpAndSupport
    }
})


export default class WizardStepAmrSmartMetering extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private selectedCustomDate = false;
    private processing = false;
    private loading = false;
    private StartDate = '';
    private EndDate = '';
    private reportStart = '';
    private reportEnd = '';
    private startMonthErrorMessage: string | null = null;
    private endMonthErrorMessage: string | null = null;
    private errorMessage: string | null = null;
    private showDownloadReport = false;
    private selectedOption = 0;

    private validator = new DateValidation();

    private now = new Date();
    private today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    private maxDate = new Date(this.today);
    private minDate = new Date(this.now.getFullYear() - 1, this.now.getMonth(), this.now.getDate());

    private GtagHelper = new GtagHelper();

    setChooseCustomDateVariant() {
        if (this.selectedCustomDate) {
            return 'sefe-blue';
        }
        return 'outline-gazprom-blue';
    }

    @Auth.Getter
    private getUserId!: string;

    @Products.Getter
    private getSelectedProduct!: string;

    @PowerAccounts.Getter
    private getSelectedAccount_Power!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @PowerAccounts.Getter
    private getSelectedMpan!: string;

    @GasAccounts.Getter
    private getSelectedMprn!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;
    
    @AmrSmartMeteringReports.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };
 
    @AmrSmartMeteringReports.Action
    private downloadReport_gas!: (params: { userId: string, mprn: string, siteRefNum: string, accountNum: string, startDate: string, endDate: string }) => Promise<void>;

    @AmrSmartMeteringReports.Action
    private downloadReport_power!: (params: { userId: string, mpan: string, account: string, startDate: string, endDate: string }) => Promise<void>;
    
    private FileProcess = new FileProcess();

    private onChooseCustomDate() {
        this.selectedCustomDate = true;
    }

    private onDownloadCustom(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.processing = false;
                return;
            }
        this.reportStart = this.StartDate
        this.reportEnd = this.EndDate
        this.onAmrSmartMeteringDownload()
        });
    }

    private onDownloadLastTwelveMonths(): void {
        this.reportStart = moment(this.minDate).format("DD/MM/YYYY");
        this.reportEnd = moment(this.maxDate).format("DD/MM/YYYY");
        this.onAmrSmartMeteringDownload()
    }

    private async setDownloadReportVisible() {
        this.showDownloadReport = true;
    }

    private async onAmrSmartMeteringDownload(): Promise<void> {
       
            try {

                if (this.getSelectedProduct == "Gas") {

                    var gasParams = {
                        userId: this.getUserId,
                        siteRefNum: this.getSelectedSiteRefNumber,
                        mprn: this.getSelectedMprn,
                        accountNum: this.getSelectedAccount,
                        startDate: this.reportStart,
                        endDate: this.reportEnd
                    }

                    await this.downloadReport_gas(gasParams);

                } else {
                    var powerParams = {
                        userId: this.getUserId,
                        account: this.getSelectedAccount_Power,
                        mpan: this.getSelectedMpan,
                        startDate: this.reportStart,
                        endDate: this.reportEnd
                    }

                    await this.downloadReport_power(powerParams);
                }

                var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                if (success) {
                    this.GtagHelper.Event("LumiDownloadAmrSmartMeteringReport", this.getUserId);
                }

            } catch (e) {
                console.error(e);
            }
    }

    private errorMessagesReset(): void {
        this.startMonthErrorMessage = null;
        this.endMonthErrorMessage = null;
        this.errorMessage = null;
    }
}
